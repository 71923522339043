import BlogPost from "../../../../components/BlogPost";
import Browser from "../../../../components/BrowserDemo";
import Lesson from "../../../../components/Lesson";
import Card from "../../../../components/Card";
import Blockquote, { Cite } from "../../../../components/Blockquote";
import * as React from 'react';
export default {
  BlogPost,
  Browser,
  Lesson,
  Card,
  Blockquote,
  Cite,
  React
};