// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-appearances-js": () => import("./../../../src/pages/appearances.js" /* webpackChunkName: "component---src-pages-appearances-js" */),
  "component---src-pages-audits-js": () => import("./../../../src/pages/audits.js" /* webpackChunkName: "component---src-pages-audits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investing-md": () => import("./../../../src/pages/investing.md" /* webpackChunkName: "component---src-pages-investing-md" */),
  "component---src-pages-oss-js": () => import("./../../../src/pages/oss.js" /* webpackChunkName: "component---src-pages-oss-js" */),
  "component---src-pages-problems-md": () => import("./../../../src/pages/problems.md" /* webpackChunkName: "component---src-pages-problems-md" */),
  "component---src-pages-story-md": () => import("./../../../src/pages/story.md" /* webpackChunkName: "component---src-pages-story-md" */),
  "component---src-pages-thoughts-billion-dollar-startup-md": () => import("./../../../src/pages/thoughts/billion-dollar-startup.md" /* webpackChunkName: "component---src-pages-thoughts-billion-dollar-startup-md" */),
  "component---src-pages-thoughts-cascade-misunderstandings-md": () => import("./../../../src/pages/thoughts/cascade-misunderstandings.md" /* webpackChunkName: "component---src-pages-thoughts-cascade-misunderstandings-md" */),
  "component---src-pages-thoughts-choice-md": () => import("./../../../src/pages/thoughts/choice.md" /* webpackChunkName: "component---src-pages-thoughts-choice-md" */),
  "component---src-pages-thoughts-css-in-js-md": () => import("./../../../src/pages/thoughts/css-in-js.md" /* webpackChunkName: "component---src-pages-thoughts-css-in-js-md" */),
  "component---src-pages-thoughts-gatsby-md": () => import("./../../../src/pages/thoughts/gatsby.md" /* webpackChunkName: "component---src-pages-thoughts-gatsby-md" */),
  "component---src-pages-thoughts-how-does-css-in-js-work-md": () => import("./../../../src/pages/thoughts/how-does-css-in-js-work.md" /* webpackChunkName: "component---src-pages-thoughts-how-does-css-in-js-work-md" */),
  "component---src-pages-thoughts-index-js": () => import("./../../../src/pages/thoughts/index.js" /* webpackChunkName: "component---src-pages-thoughts-index-js" */),
  "component---src-pages-thoughts-inertia-md": () => import("./../../../src/pages/thoughts/inertia.md" /* webpackChunkName: "component---src-pages-thoughts-inertia-md" */),
  "component---src-pages-thoughts-margin-md": () => import("./../../../src/pages/thoughts/margin.md" /* webpackChunkName: "component---src-pages-thoughts-margin-md" */),
  "component---src-pages-thoughts-my-dream-styling-react-apps-md": () => import("./../../../src/pages/thoughts/my-dream-styling-react-apps.md" /* webpackChunkName: "component---src-pages-thoughts-my-dream-styling-react-apps-md" */),
  "component---src-pages-thoughts-node-js-background-jobs-md": () => import("./../../../src/pages/thoughts/node-js-background-jobs.md" /* webpackChunkName: "component---src-pages-thoughts-node-js-background-jobs-md" */),
  "component---src-pages-thoughts-regrets-at-spectrum-md": () => import("./../../../src/pages/thoughts/regrets-at-spectrum.md" /* webpackChunkName: "component---src-pages-thoughts-regrets-at-spectrum-md" */),
  "component---src-pages-thoughts-spectrum-technical-hindsight-part-2-md": () => import("./../../../src/pages/thoughts/spectrum-technical-hindsight-part-2.md" /* webpackChunkName: "component---src-pages-thoughts-spectrum-technical-hindsight-part-2-md" */),
  "component---src-pages-thoughts-streaming-ssr-md": () => import("./../../../src/pages/thoughts/streaming-ssr.md" /* webpackChunkName: "component---src-pages-thoughts-streaming-ssr-md" */),
  "component---src-pages-thoughts-tailwind-md": () => import("./../../../src/pages/thoughts/tailwind.md" /* webpackChunkName: "component---src-pages-thoughts-tailwind-md" */),
  "component---src-pages-thoughts-tech-choice-regrets-at-spectrum-md": () => import("./../../../src/pages/thoughts/tech-choice-regrets-at-spectrum.md" /* webpackChunkName: "component---src-pages-thoughts-tech-choice-regrets-at-spectrum-md" */),
  "component---src-pages-thoughts-the-future-is-server-side-md": () => import("./../../../src/pages/thoughts/the-future-is-server-side.md" /* webpackChunkName: "component---src-pages-thoughts-the-future-is-server-side-md" */),
  "component---src-pages-thoughts-why-we-open-sourced-our-product-md": () => import("./../../../src/pages/thoughts/why-we-open-sourced-our-product.md" /* webpackChunkName: "component---src-pages-thoughts-why-we-open-sourced-our-product-md" */),
  "component---src-pages-uses-js": () => import("./../../../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

